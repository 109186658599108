import { useNavigate, useParams } from 'react-router-dom';
import { isAfter, parseISO, subDays } from 'date-fns';
import { Typography } from '@mui/material';
import { GridColDef, GridEventListener, GridRenderCellParams } from '@mui/x-data-grid';
import { useTracking } from '@/hooks/useTracking';
import { useEntitiesList } from '@/providers/EntitiesListContextProvider/EntitiesListContext.hooks';
import { mapSeverityChartData } from '@/helpers/mapChartsData';
import { formatToUSD } from '@/helpers/helpers';
import ExchangeLogo from '@/components/ExchangeLogo/ExchangeLogo';
import { RiskIndicator } from '@/components/RiskIndicator/RiskIndicator';
import { AlertSeverityBarsIcon } from '@/components/AlertSeverityBarsIcon/AlertSeverityBarsIcon';
import { SeverityTrend } from '@/views/MonitoringDashboardView/components/SeverityTrend/SeverityTrend';
import {
  StyledDataGrid,
  StyledLogoWrapper,
} from '@/views/MonitoringDashboardView/components/MonitoredEntitiesTable/MonitoredEntitiesTable.styled';
import { IMonitoredEntity } from '@/api/monitoring/monitoring.types';
import { ERoutes } from '@/types/routes';
import { useSortingManager } from '@/views/MonitoringDashboardView/components/MonitoredEntitiesTable/useSortingManager';
import { ChartData } from '@/types/chartPoint';

interface IMonitoredEntitiesTableProps {
  monitoredEntities: IMonitoredEntity[];
}

export const MonitoredEntitiesTable = ({ monitoredEntities }: IMonitoredEntitiesTableProps) => {
  const { dashboardType } = useParams();
  const { getExchangeLabelByDisplayName } = useEntitiesList();
  const { trackEvent } = useTracking();
  const navigate = useNavigate();
  const { sortModel, updateSortModel, riskIndicatorComparator, severityComparator } = useSortingManager();

  if (!monitoredEntities.length && dashboardType === 'peak') {
    return (
      <>
        <Typography color="white.100" fontSize="24px" fontWeight="bold" align="center">
          Great news!
        </Typography>
        <Typography color="white.100" align="center">
          No active <strong>HIGH</strong> or <strong>EXTREME</strong> severity levels detected among the monitored
          entities.
        </Typography>
      </>
    );
  }

  const mappedEntities = monitoredEntities.map((entity) => {
    const areLatestAnomaliesProvided = entity.latestAnomalies.length;

    const latestAnomaly = areLatestAnomaliesProvided ? entity.latestAnomalies[0] : { severity: null };
    const latestBalance = entity.latestBalance?.value || null;

    let chartData: ChartData = [];

    if (areLatestAnomaliesProvided) {
      const lastAnomalyDataTimestamp = parseISO(entity.latestAnomalies[0].timestamp);
      const previousDay = subDays(lastAnomalyDataTimestamp, 1);

      const latestAnomaliesFromTheLastDay = entity.latestAnomalies.filter((anomaly) =>
        isAfter(parseISO(anomaly.timestamp), previousDay),
      );

      const mappedLatestAnomalies = latestAnomaliesFromTheLastDay.map((anomaly) => {
        return {
          timestamp: anomaly.timestamp,
          value: anomaly.severity,
        };
      });

      chartData = mapSeverityChartData(mappedLatestAnomalies);
    }

    return {
      ...entity,
      severity: latestAnomaly.severity,
      chartData: chartData,
      latestBalance,
    };
  });

  const handleRowClick: GridEventListener<'rowClick'> = (params) => {
    const href = params.row.displayName
      ? `${ERoutes.MONITORING_ENTITY}/${getExchangeLabelByDisplayName(params.row.displayName)}`
      : '';

    trackEvent('Monitoring - details click', {
      entity: params.row.displayName || 'unknown',
    });

    navigate(href);
  };

  const columns: GridColDef[] = [
    {
      field: 'displayName',
      headerName: 'Company',
      headerAlign: 'left',
      minWidth: 250,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <StyledLogoWrapper>
            <ExchangeLogo companyName={getExchangeLabelByDisplayName(params.row.displayName)} height="50px" />
            {params.row.displayName}
          </StyledLogoWrapper>
        );
      },
    },
    {
      field: 'severity',
      headerName: 'Severity',
      headerAlign: 'center',
      align: 'center',
      sortComparator: (v1, v2) => severityComparator(v1, v2),
      minWidth: 150,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        const severity = params.row.severity;

        if (!severity) return null;

        return <AlertSeverityBarsIcon severity={severity} />;
      },
    },
    {
      field: 'latestBalance',
      headerName: 'Latest Balance',
      headerAlign: 'right',
      align: 'right',
      minWidth: 200,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        const value = params.row.latestBalance;

        if (!value) return null;

        return (
          <Typography color="white.100" fontSize="14px" align="right">
            {formatToUSD(value, true)}
          </Typography>
        );
      },
    },
    {
      field: 'riskIndicator',
      headerName: 'Trending Risk',
      headerAlign: 'center',
      align: 'center',
      sortComparator: (v1, v2) => riskIndicatorComparator(v1, v2),
      minWidth: 150,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        const riskIndicator = params.row.riskIndicator;

        if (riskIndicator === null) {
          return <RiskIndicator riskIndicator={'pending'} />;
        }

        return <RiskIndicator riskIndicator={params.row.riskIndicator} />;
      },
    },
    {
      field: 'chartData',
      headerName: 'Trending Risk Chart',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
      filterable: false,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        if (!params.row.chartData.length) return null;

        return <SeverityTrend chartData={params.row.chartData} />;
      },
    },
  ];

  return (
    <StyledDataGrid
      style={{ color: '#fff' }}
      rows={mappedEntities}
      getRowId={(params) => params.agioId}
      onRowClick={handleRowClick}
      columns={columns}
      hideFooter
      sortModel={sortModel}
      onSortModelChange={updateSortModel}
    />
  );
};
