import styled from '@emotion/styled';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 22px 24px;
  width: 100%;
`;

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 25px;
  border: 1px solid #2e2e31;
  width: 100%;
`;

export const StyledLoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  width: 100%;
`;

export const StyledTextWrapper = styled.div`
  color: #f7f7f7;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  overflow: auto;
`;
