import { Typography } from '@mui/material';
import Zoom from '@mui/material/Zoom';
import HelpIcon from '@/assets/icons/help.svg';
import Tooltip from '@/components/atoms/Tooltip/Tooltip';
import { RiskIndicator } from '@/components/RiskIndicator/RiskIndicator';
import { StyledTooltipContent } from '@/views/EntityMonitoringView/components/EntityDetails/EntityDetails.styled';
import { TRiskIndicator } from '@/types/severityLevel';

interface ITrendingRiskProps {
  riskIndicator: TRiskIndicator | null;
}

export const TrendingRisk = ({ riskIndicator }: ITrendingRiskProps) => {
  return (
    <>
      <div>
        <Tooltip
          arrow
          TransitionComponent={Zoom}
          placement="left"
          title={
            <p>
              The <strong>Trending Risk</strong> indicator shows how the risk severity for a given entity has changed
              over a specific period. It highlights whether the risk is <strong>increasing</strong>,{' '}
              <strong>decreasing</strong>, or <strong>stable</strong>, based on a comparison of{' '}
              <strong>hourly severity levels</strong> over time. The calculation compares the sum of hourly severity
              levels from the current day to the previous day to determine the trend.
            </p>
          }
        >
          <StyledTooltipContent>
            <Typography color="#ffffff80" fontSize="14px">
              Trending Risk
            </Typography>
            <img src={HelpIcon} alt="Help icon" width="16px" height="16px" />
          </StyledTooltipContent>
        </Tooltip>
      </div>

      <RiskIndicator riskIndicator={riskIndicator} />
    </>
  );
};
